import React, { useState } from 'react';
import styled from '@emotion/styled';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Person, CheckCircle } from '@mui/icons-material';
import { checkSufficientBalance } from '../services/walletService';
import { useNavigate } from 'react-router-dom';

const StyledCard = styled.div`
  background: white;
  border-radius: 12px;
  border: 1px solid #e0e0e0;
  margin: 8px 0;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  cursor: pointer;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
  padding: 16px;
  max-width: 350px;
  width: 100%;
  box-sizing: border-box;
  overflow: hidden;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  }
`;

const CardContent = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
`;

const InfoRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const AmountDisplay = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const AmountLabel = styled.span`
  color: black;
  font-size: 16px;
  font-weight: 500;
`;

const AmountValue = styled.span`
  color: black;
  font-size: 18px;
  font-weight: 600;
`;

const EntryValue = styled(AmountValue)`
  font-size: 20px;
  color: black;
  font-weight: 700;
`;

const PlayButton = styled(Button)`
  padding: 8px 32px;
  font-weight: 600;
  border-radius: 6px;
  text-transform: none;
  font-size: 14px;
  background-color: #2e7d32;
  color: white;
  white-space: nowrap;
  &:hover {
    background-color: #1b5e20;
  }
  &:disabled {
    background-color: #e0e0e0;
    color: #999;
  }
`;

const PlayerInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 16px;
`;

interface CashGameCardProps {
  cashGame: {
    id: string;
    amount: number;
    playersCount: number;
    winning: number;
  };
}

const CashGameCard: React.FC<CashGameCardProps> = ({ cashGame }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleJoin = async () => {
    setIsLoading(true);
    try {
      const hasBalance = await checkSufficientBalance(cashGame.amount);
      if (hasBalance) {
        setOpenDialog(true);
      } else {
        alert('Insufficient balance. Please add money to your wallet.');
      }
    } catch (error) {
      console.error('Error checking balance:', error);
      alert('An error occurred. Please try again.');
    }
    setIsLoading(false);
  };

  const confirmJoin = () => {
    setOpenDialog(false);
    navigate('/matchmaking', { state: { gameAmount: cashGame.amount } });
  };

  const displayAmount = cashGame.amount === 0 ? 'Free' : `₹${cashGame.amount}`;

  return (
    <StyledCard>
      <CardContent>
        <InfoRow>
          <AmountDisplay>
            <AmountLabel>Prize:</AmountLabel>
            <AmountValue>₹{cashGame.winning}</AmountValue>
          </AmountDisplay>
          <AmountDisplay>
            <AmountLabel>Entry:</AmountLabel>
            <EntryValue>{displayAmount}</EntryValue>
          </AmountDisplay>
        </InfoRow>
        
        <InfoRow>
          <PlayerInfo>
            <Person sx={{ fontSize: 18 }} />
            <Typography variant="body2">
              {cashGame.playersCount} Players
            </Typography>
          </PlayerInfo>
          <PlayButton
            onClick={handleJoin}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Play'}
          </PlayButton>
        </InfoRow>
      </CardContent>

      <Dialog 
        open={openDialog} 
        onClose={() => setOpenDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: '12px',
            padding: '8px',
            maxWidth: '90vw',
            margin: '16px'
          }
        }}
      >
        <DialogTitle>Confirm Join</DialogTitle>
        <DialogContent>
          {cashGame.amount === 0 
            ? 'Are you sure you want to join this free game?' 
            : `Are you sure you want to join this game? ₹${cashGame.amount} will be deducted from your wallet.`
          }
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setOpenDialog(false)}
            sx={{ color: '#666' }}
          >
            Cancel
          </Button>
          <Button 
            onClick={confirmJoin}
            sx={{
              backgroundColor: '#2e7d32',
              color: 'white',
              '&:hover': {
                backgroundColor: '#1b5e20'
              }
            }}
          >
            Join
          </Button>
        </DialogActions>
      </Dialog>
    </StyledCard>
  );
};

export default CashGameCard;