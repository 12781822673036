import { CONFIG } from '../config';
import { getIdToken, getUserId } from './authService';
import { WalletBalance, WithdrawalRequest, KYCStatus } from '../types/wallet';
import { razorpayService } from './razorpayService';


// walletService.ts
export const getBalance = async (): Promise<WalletBalance> => {
  const token = await getIdToken();
  const userId = await getUserId();
  console.log('Fetching balance for user:', userId); // Add logging

  const response = await fetch(`${CONFIG.backendUrl}/payments/wallet/${userId}`, {
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });

  if (!response.ok) {
      console.error('Failed to fetch balance:', response.status, response.statusText);
      throw new Error('Failed to fetch wallet balance');
  }

  const data = await response.json();
  console.log('Received balance data:', data); // Add logging

  // Check the response structure and handle it accordingly
  const balance = data.data || data;
  return {
      total: Number(balance.total) || 0,
      deposits: Number(balance.deposits) || 0,
      winnings: Number(balance.winnings) || 0
  };
};

export const withdrawMoney = async (request: WithdrawalRequest): Promise<void> => {
  const token = await getIdToken();
  const response = await fetch(`${CONFIG.backendUrl}/payments/withdraw`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(request),
  });
  
  if (!response.ok) {
    throw new Error('Failed to withdraw money');
  }
};

export const checkSufficientBalance = async (amount: number): Promise<boolean> => {
  try {
    const balance = await getBalance();
    return balance.total >= amount;
  } catch (error) {
    console.error('Error checking balance:', error);
    throw error;
  }
};

export const getKYCStatus = async (): Promise<KYCStatus> => {
  const token = await getIdToken();
  const response = await fetch(`${CONFIG.backendUrl}/payments/kyc/status`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch KYC status');
  }

  return await response.json();
};

export const initiateDeposit = async (amount: number): Promise<string> => {
  const order = await razorpayService.createOrder(amount);
  return order.orderId;
};

export const verifyDeposit = async (
  orderId: string,
  paymentId: string,
  signature: string
): Promise<boolean> => {
  return await razorpayService.verifyPayment(orderId, paymentId, signature);
};


// src/services/walletService.ts
export const initiateKYC = async (): Promise<{ redirectUrl: string }> => {
  try {
    const token = await getIdToken();
    const response = await fetch(`${CONFIG.backendUrl}/payments/kyc/initiate`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error('Failed to initiate KYC');
    }

    const data = await response.json();
    if (!data.redirectUrl) {
      throw new Error('No redirect URL received from server');
    }

    return { redirectUrl: data.redirectUrl };
  } catch (error) {
    console.error('KYC initiation error:', error);
    throw error;
  }
};